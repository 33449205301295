@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@media (min-width: 1200px) {
  .ck.ck-editor {
    max-width: 960px;
  }
}
/*PC bas résolution , Tablette , Mobile*/
@media (max-width: 1199.98px) {
  .table-spec .main-spinner {
    overflow: auto;
    max-height: 600px;
  }
  .table-spec .main-spinner table.mat-table {
    min-width: 1070px !important;
  }

  .table-responsive {
    overflow: auto;
    max-height: 600px;
  }
  .table-responsive table.mat-table {
    min-width: 1070px !important;
  }

  .show-egp p {
    margin-bottom: 20px !important;
  }

  .show-egp p,
.show-egp h3 {
    margin-bottom: 20px !important;
  }

  .show-egp span {
    position: inherit !important;
  }

  .navbar-collapse.collapse.show {
    background: #00b6d7;
    padding: 10px 15px;
    box-shadow: 0px 4px 16px -10px #292929;
  }

  .navbar-toggler {
    position: absolute;
    right: 0;
    /* background: #ffffff;*/
  }

  .nav-item {
    text-align: center;
    width: 200px;
    margin: 0 auto !important;
  }
  .nav-item.dropdown.dmenu {
    text-align: center;
  }

  header nav .navbar-collapse .option-part {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header nav .navbar-collapse .option-part .armp-icon {
    margin: 0 8px !important;
  }

  .flex-form {
    display: block !important;
  }
  .flex-form .form-control {
    width: 100% !important;
  }

  .formflex-inflex {
    width: 100% !important;
  }
  .formflex-inflex .custom-input {
    width: 80% !important;
  }
  .formflex-inflex .selectWidth {
    width: 120px !important;
  }

  .responsive-datePicker .icon-date-picker:after {
    top: 36px !important;
  }
  .responsive-datePicker .arrow-select:after {
    top: 41px !important;
  }
  .responsive-datePicker .formflex-inflex .arrow-select:after {
    top: 13px !important;
  }

  .editorWidth {
    width: 100% !important;
  }

  .minWidth_Chart {
    min-width: 700px;
  }

  .arrowS-Edit_top:after {
    top: 50px !important;
  }

  .w-params {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}
/*PC bas résolution*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .card.card-portail {
    width: 100% !important;
  }

  .ck.ck-editor {
    max-width: 790px;
  }
}
/*Tablette*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .show-egp p,
.show-egp h3 {
    margin-bottom: 20px !important;
  }

  .show-egp span {
    position: inherit !important;
  }

  .login {
    width: 60%;
    margin: 0 auto;
  }

  .e-management.statistique {
    display: block;
    justify-content: space-between;
  }
  .e-management.statistique .recherche {
    width: 100%;
  }
  .e-management.statistique .resultat-recherche {
    width: 100%;
  }

  .icon-date-picker:after {
    top: 35px !important;
  }

  .responsiveBtn {
    display: block !important;
  }
  .responsiveBtn .btn {
    width: 100% !important;
    margin: 0 0 10px 0px !important;
  }
  .responsiveBtn .customResponsive-btn1 {
    width: 115px !important;
  }
  .responsiveBtn .customResponsive-btn2 {
    justify-content: left;
  }
  .responsiveBtn .customResponsive-btn2 .btn {
    width: 115px !important;
    margin-left: 20px;
  }
  .responsiveBtn .longTxt-btn {
    width: auto !important;
    height: auto;
  }
  .responsiveBtn .none-margin {
    margin: 0 !important;
  }
  .responsiveBtn .customResponsive-secondbtn {
    margin-left: 10px !important;
  }

  .card-action .btn {
    margin-bottom: 10px !important;
  }

  .sub-eval {
    left: 152px !important;
    top: 65px !important;
  }

  .sub-eval2 {
    left: 152px !important;
    top: 27px !important;
  }

  .sub-contrat {
    left: 180px;
    top: 125px !important;
  }

  .sub-contrat2 {
    left: 180px !important;
    top: 28px !important;
  }

  .sub-dossier {
    top: 25px !important;
    left: 130px;
  }

  .sub-dossier2 {
    top: 25px !important;
    left: 130px;
  }

  .ck.ck-editor {
    max-width: 599px;
  }

  footer .d-flex .left-foot {
    flex: 0 0 38% !important;
    font-size: 12px !important;
  }
  footer .d-flex .center-foot {
    flex: 0 0 29% !important;
    font-size: 12px !important;
  }
  footer .d-flex .right-foot {
    flex: 0 0 33% !important;
    font-size: 12px !important;
  }
}
/*Mobile*/
@media (min-width: 320px) and (max-width: 767.98px) {
  .e-management.statistique {
    display: block;
    justify-content: space-between;
  }
  .e-management.statistique .recherche {
    width: 100%;
  }
  .e-management.statistique .resultat-recherche {
    width: 100%;
  }

  .minWidth_Chart2 {
    min-width: 500px;
  }

  .responsiveBtn {
    display: block !important;
  }
  .responsiveBtn .btn {
    width: 100% !important;
    margin: 0 0 10px 0px !important;
  }
  .responsiveBtn .customResponsive-btn1 {
    width: 115px !important;
  }
  .responsiveBtn .customResponsive-btn2 {
    justify-content: left;
  }
  .responsiveBtn .customResponsive-btn2 .btn {
    width: 115px !important;
    margin-left: 10px;
  }
  .responsiveBtn .longTxt-btn {
    width: auto !important;
    height: auto;
  }
  .responsiveBtn .none-margin {
    margin: 0 !important;
  }
  .responsiveBtn .customResponsive-secondbtn {
    margin-left: 10px !important;
  }

  .icon-date-picker:after {
    top: 35px !important;
  }

  .card-action .btn {
    margin-bottom: 10px !important;
  }

  .form2_responsiveBtn {
    display: block !important;
    margin-bottom: 0 !important;
  }
  .form2_responsiveBtn .btn {
    width: 100% !important;
    margin: 0 0 10px 0px !important;
  }

  .calc-height-matContentModal {
    height: calc(100% - 152px - 42px) !important;
  }

  .nav-item {
    width: 80%;
  }

  .responsive-dropdownM {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .sub-eval {
    top: 65px !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
  }

  .sub-eval2 {
    top: 27px !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
  }

  .sub-contrat {
    top: 125px !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
  }

  .sub-contrat2 {
    top: 28px !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
  }

  .sub-dossier {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
    top: 30px !important;
  }

  .sub-dossier2 {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
    top: 30px !important;
  }

  .params-responsive-form {
    display: block !important;
  }
  .params-responsive-form div {
    max-width: none !important;
    padding: 0;
  }
  .params-responsive-form div input {
    width: 100% !important;
  }
  .params-responsive-form div select {
    width: 100%;
  }
}
@media (min-width: 625px) and (max-width: 767.98px) {
  footer {
    height: 35px !important;
  }
  footer .d-flex .left-foot {
    flex: 0 0 38% !important;
    font-size: 10px !important;
  }
  footer .d-flex .center-foot {
    flex: 0 0 29% !important;
    font-size: 10px !important;
  }
  footer .d-flex .right-foot {
    flex: 0 0 33% !important;
    font-size: 10px !important;
  }
}
@media (max-width: 624px) {
  footer {
    height: 45px !important;
  }
  footer .d-flex .left-foot {
    flex: 0 0 38% !important;
    font-size: 10px !important;
  }
  footer .d-flex .center-foot {
    flex: 0 0 29% !important;
    font-size: 10px !important;
    text-align: center !important;
  }
  footer .d-flex .right-foot {
    flex: 0 0 33% !important;
    font-size: 10px !important;
  }
}
@media (max-width: 320px) {
  .responsive-mobile {
    text-align: center;
    margin-top: 20px;
  }

  .the-legend {
    margin: auto;
  }

  .responsiveBtn {
    display: block !important;
  }
  .responsiveBtn .btn {
    width: 100% !important;
    margin: 0 0 10px 0px !important;
  }
  .responsiveBtn .customResponsive-btn1 {
    width: 115px !important;
  }
  .responsiveBtn .customResponsive-btn2 {
    justify-content: left;
  }
  .responsiveBtn .customResponsive-btn2 .btn {
    width: 115px !important;
    margin-left: 20px;
  }
  .responsiveBtn .longTxt-btn {
    width: auto !important;
    height: auto;
  }
  .responsiveBtn .none-margin {
    margin: 0 !important;
  }
  .responsiveBtn .customResponsive-secondbtn {
    margin-left: 10px !important;
  }

  .card-action .btn {
    margin-bottom: 10px !important;
  }
}
@media (min-width: 565px) and (max-width: 767.98px) {
  .ck.ck-editor {
    max-width: 429px;
  }
}
@media (max-width: 564px) {
  .ck.ck-editor {
    max-width: 229px !important;
  }
}
@media (max-width: 575px) {
  .m-topBottom-responsiveBtn {
    margin: 20px 0 !important;
  }

  .j-content-form {
    justify-content: left !important;
  }
}
@media (max-width: 478px) {
  .date-picker_other:after {
    top: 46px !important;
  }
}
@media (max-width: 979px) {
  .performance .content-modal-height {
    height: calc(100% - 120px) !important;
    max-height: 90%;
    overflow: auto !important;
  }
  .performance .width-close-modal {
    height: 50px !important;
    width: 100%;
  }
  .performance .h-legendModal {
    height: 70px !important;
  }
}
@media (max-width: 931px) {
  .modal-content-responsive-form {
    height: calc(100% - 43px - 107px) !important;
  }

  .responsive-btn-modalForm {
    display: block !important;
  }
  .responsive-btn-modalForm .btn {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .h-33 h4 {
    padding: 0;
  }

  .responsive-formulaireModal {
    display: block;
  }
  .responsive-formulaireModal div {
    max-width: none !important;
    padding: 0;
  }

  .responsive-formulaireModal2 {
    display: block;
  }
  .responsive-formulaireModal2 div {
    max-width: none;
  }
}
@media (max-width: 890px) {
  .responsiveForm-modal {
    display: block !important;
  }
  .responsiveForm-modal div {
    margin: 1rem 0;
  }
  .responsiveForm-modal div .form-select {
    width: 100%;
  }
  .responsiveForm-modal .btn {
    width: 100%;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 709px) {
  .responsive-radioAccordion {
    display: flex;
    align-items: baseline;
  }
}
@keyframes debounce_ {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.95);
  }
}
.text-danger, .text-warning {
  font-size: 12px !important;
  padding: 3px 5px !important;
}

* {
  font-family: "Ubuntu", sans-serif;
  outline: none;
}

html,
body {
  background: #f4f7fc;
}

.auth-container {
  max-width: 800px;
  margin-top: 50px;
}
.auth-container h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #00b6d7;
  text-align: center;
  margin-top: 25px;
}
.auth-container p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #a1aeb7;
  text-align: center;
  padding: 0 20px;
}
.auth-container .row {
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
}
.auth-container .row > div {
  padding: 0;
}
.auth-container .row form {
  margin: 15px 50px;
}
.auth-container .row form .form-control {
  font-family: "Ubuntu", sans-serif;
}
.auth-container .row form .form-label {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #505d68;
  margin: 0;
  margin-bottom: 10px;
  font-family: "Ubuntu", sans-serif;
}
.auth-container .row form .form-check a {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #00b6d7;
  text-decoration: none;
  text-align: center;
  display: block;
  transition: underline 0.4s ease-in-out;
}
.auth-container .row form .form-check a:hover {
  text-decoration: underline !important;
}
.auth-container .row form hr {
  background: #f4f7fc;
  margin: 25px 0px;
}
.auth-container .row form .btn {
  width: 100%;
  box-shadow: 0px 10px 10px rgba(0, 182, 215, 0.2);
  border-radius: 50px;
  margin: 5px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  height: 45px;
}
.auth-container .row form .btn-send {
  background: #00b6d7;
  color: #ffffff;
  transition: all 0.6s ease-in-out !important;
}
.auth-container .row form .btn-send:hover {
  background: #f4f7fc;
  color: #4f4f4f;
}
.auth-container .row form .btn-reset {
  background: #f4f7fc;
  color: #4f4f4f;
  transition: all 0.6s ease-in-out !important;
}
.auth-container .row form .btn-reset:hover {
  background: #00b6d7;
  color: #ffffff;
}

.main-page {
  margin-bottom: 100px;
}
.main-page h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #4f4f4f;
  margin: 15px 0 0;
}
.main-page .breadcrumbs {
  padding: 0;
  margin: 0;
  display: flex;
  margin-top: 5px;
}
.main-page .breadcrumbs li {
  list-style-type: none;
  margin-right: 15px;
  position: relative;
}
.main-page .breadcrumbs li::after {
  content: "/";
  position: absolute;
  top: 0;
  right: -10px;
  width: 5px;
  height: 20px;
  color: #4f4f4f;
}
.main-page .breadcrumbs li:last-child::after {
  display: none;
}
.main-page .breadcrumbs li a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #4f4f4f;
  text-decoration: none;
}
.main-page .card-bg {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  margin-top: 25px;
  font-family: "Ubuntu", sans-serif;
}
.main-page .card-bg h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #00b6d7;
  margin: 10px 0px 15px;
}

.card-action {
  text-align: center;
  width: 100%;
}
.card-action .btn {
  border-radius: 4px;
  width: 194px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0 15px;
  height: 35px;
}
.card-action .btn-cancel-form {
  background: #e0e0e0 !important;
  color: #828282 !important;
  transition: all 0.4s ease-in-out !important;
}
.card-action .btn-cancel-form:hover {
  background: #00b6d7;
  color: #ffffff;
}
.card-action .btn-send-form {
  background: #00b6d7;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}
.card-action .btn-send-form:hover {
  background: #0796af;
  color: #F4F7FC;
  transition: 0.4s;
}

.mat-custom-table {
  width: 100%;
  font-family: "Ubuntu", sans-serif;
}
.mat-custom-table thead tr {
  height: 46px;
}
.mat-custom-table thead tr th {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  border-bottom: none;
  padding: 0 12px;
}
.mat-custom-table tbody tr {
  height: 46px;
}
.mat-custom-table tbody tr:nth-child(2n-1) td {
  background: #f9f9f9;
}
.mat-custom-table tbody tr td {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a1aeb7;
  border-bottom: none;
  padding: 5px 10px;
}
.mat-custom-table tbody tr td .form-control {
  background: #ffffff;
}

.main-show-element label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
}
.main-show-element span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}

.btn-add {
  background: #00b6d7;
  /*box-shadow: 0px 10px 10px rgba(0, 182, 215, 0.2);*/
  border-radius: 4px;
  height: 35px;
  border: none;
  color: #ffffff;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-left: auto;
  position: relative;
  margin-top: 0.3rem;
  transition: all 0.4s ease-in-out;
}
.btn-add:hover {
  /*color: #4f4f4f;
  background: #f4f7fc;
  border: 1px solid $color-blue;*/
  background: #0796af;
  color: #F4F7FC;
  transition: 0.4s;
}
.btn-add .armp-icon-plus {
  font-size: 12px;
  margin-right: 5px;
}

.main-table-filter {
  position: relative;
  top: 0;
  height: 80px;
}
.main-table-filter .arrow-select {
  width: 100%;
  max-width: 270px;
}
.main-table-filter select {
  display: block;
  height: 35px;
  background: #f4f7fc;
  border: 1px solid #00b6d7;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a1aeb7;
  padding: 0 35px 0 10px;
  width: 100%;
  max-width: 270px;
}

.arrowS-Edit2_top:after {
  top: 50px !important;
}

.arrow-NoneLabel:after {
  top: 13px !important;
}

.form-select {
  display: block;
  height: 35px;
  background: #f4f7fc;
  border: 1px solid #00b6d7;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a1aeb7;
  padding: 0 35px 0 10px;
  width: 270px;
}

.table-actions {
  display: flex;
  padding: 0;
  margin: 0;
}
.table-actions li {
  margin: 0px 10px;
  list-style-type: none;
  color: #00b6d7;
  position: relative;
}
.table-actions li .table-icons,
.table-actions li span {
  position: relative;
  top: 8px;
  font-size: 18px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.table-actions li .table-icons:hover,
.table-actions li span:hover {
  color: inherit;
}
.table-actions li .switch {
  position: relative;
  top: 2px;
}
.table-actions li.align-icon span {
  position: relative;
  top: 0px;
}

.table-action-ajout {
  display: flex;
  padding: 0;
  margin-left: 0;
}
.table-action-ajout li {
  margin: 0px 5px;
  list-style-type: none;
  color: #ffffff;
  position: relative;
}
.table-action-ajout li span {
  position: relative;
  top: 8px;
  font-size: 16px;
  cursor: pointer;
  background: #00b6d7;
  border-radius: 50%;
  padding: 4px;
}
.table-action-ajout li label {
  position: relative;
  top: 8px;
  font-size: 14px;
  cursor: pointer;
  color: #00b6d7;
}
.table-action-ajout li .switch {
  position: relative;
  top: 8px;
}

.cdk-overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.cdk-overlay-container .cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}
.cdk-overlay-container .cdk-global-overlay-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
  margin: auto;
  background: #ffffff;
}

.arrow-select {
  position: relative;
}
.arrow-select:after {
  content: "";
  font-family: "icoArmp";
  font-size: 9px;
  position: absolute;
  top: 40px;
  right: 5px;
  width: 20px;
  height: 20px;
  color: #00b6d7;
  pointer-events: none;
}
.arrow-select select {
  appearance: none;
}

:host ::ng-deep app-btn-ajout-offre {
  float: right;
  margin-bottom: 15px;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #00b6d7;
}

.mat-typography .form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
}
.mat-typography .form-control {
  background: #f4f7fc;
  border-radius: 4px;
  border: none;
  height: 35px;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
}

.form-control {
  background-color: #f4f7fc;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  outline: 0;
}

textarea.form-control {
  height: 95px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f4f7fc;
  pointer-events: none;
  opacity: 0.6;
  border: 1px solid #cfd1d4;
}

/* custom scrollbar by css3 */
.scroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.scroller::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

body,
.mat-dialog-container {
  overflow: auto;
}
body::-webkit-scrollbar,
.mat-dialog-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body::-webkit-scrollbar-track,
.mat-dialog-container::-webkit-scrollbar-track {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb,
.mat-dialog-container::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.btn-inscription {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 43px;
  color: #4f4f4f;
  background: #f4f7fc;
  box-shadow: 0px 10px 10px rgba(0, 182, 215, 0.2);
  border-radius: 50px;
  height: 45px;
  margin: 15px 50px;
  text-align: center;
  display: block;
  text-decoration: none;
  transition: all 0.6s ease-in-out !important;
}
.btn-inscription:hover {
  background: #00b6d7;
  color: #ffffff;
  text-decoration: none;
}

.event-none {
  pointer-events: none;
}

.val-auto-complet {
  border-bottom: 1px dashed #8e8e8e;
  color: #3e6fda;
  font-weight: 500;
}

.val-pre-remplie {
  display: inline-block;
  /* border-bottom: 1px dashed #8e8e8e; */
  /* color: #3e6fda; */
  font-weight: 500;
  text-align: center;
}

.val-pre-remplie2 {
  border-bottom: 1px dashed #8e8e8e !important;
  min-width: 80px !important;
  color: #3e6fda !important;
  font-weight: 500 !important;
}

.add-list-btn {
  background: #00b6d7;
  color: #ffffff;
  border: 1px solid #00b6d7;
  border-radius: 50%;
  transition: all ease 0.6ms;
  padding: 2px;
}

.add-list-btn:hover {
  background: none;
}

.disablade-block {
  pointer-events: none;
  opacity: 0.2;
}

.hidden-element {
  display: none;
}

.styled-checkbox-armp {
  position: absolute;
  opacity: 0;
}
.styled-checkbox-armp + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.styled-checkbox-armp + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #00b6d7;
  border-radius: 2px;
}
.styled-checkbox-armp:hover + label:before {
  background: #00b6d7;
}
.styled-checkbox-armp:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox-armp:checked + label:before {
  background: #00b6d7;
}
.styled-checkbox-armp:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox-armp:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox-armp:checked + label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 7px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.icon-date-picker {
  position: relative;
}
.icon-date-picker:after {
  content: "";
  font-family: "icoArmp";
  font-size: 14px;
  position: absolute;
  top: 35px;
  right: 5px;
  width: 20px;
  height: 20px;
  color: #00b6d7;
  pointer-events: none;
}

.date-picker_Edit:after {
  top: 46px !important;
}

.icon-date-picker-form {
  position: relative;
}
.icon-date-picker-form:after {
  content: "";
  font-family: "icoArmp";
  font-size: 14px;
  position: absolute;
  top: 8px;
  right: 5px;
  width: 20px;
  height: 20px;
  color: #00b6d7;
  pointer-events: none;
}

.main-shitch-radio {
  line-height: 5px;
}
.main-shitch-radio input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.main-shitch-radio label {
  cursor: pointer;
  text-indent: -9999px;
  width: 26px;
  height: 17px;
  background: #acb0b1;
  display: block;
  border-radius: 100px;
  position: relative;
}
.main-shitch-radio label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}
.main-shitch-radio input:checked + label {
  background: #00b6d7;
}
.main-shitch-radio input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.main-shitch-radio label:active:after {
  width: 22px;
}

.align-self-end {
  align-self: flex-end;
}

.mat-dialog-content {
  height: calc(100% - 130px);
}
.mat-dialog-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.mat-dialog-content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.mat-dialog-content::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-calendar-picker-indicator {
  position: relative;
  z-index: 2;
  right: -4px;
  opacity: 0;
  cursor: pointer;
}

.icon-times {
  position: relative;
}
.icon-times:after {
  content: "";
  font-family: "icoArmp";
  font-size: 14px;
  position: absolute;
  top: 7px;
  right: 5px;
  width: 20px;
  height: 20px;
  color: #00b6d7;
}

.rectifalign_global {
  margin-bottom: 20px !important;
}

.widthAllF {
  width: 100%;
}

.annulmargin_bottomF {
  margin-bottom: 0 !important;
}

.displayrectificat {
  display: block !important;
}

.rectific_font {
  font-size: 14px !important;
  font-weight: bolder !important;
  color: #00000073 !important;
}

.rectific_font2 {
  font-size: 14px !important;
}

.alignCPS_3 {
  margin-bottom: 25px !important;
}

.cpsTrx_1 {
  height: 70px;
}

.scrollmodalModif {
  display: inline !important;
}

.formdropf {
  height: 50px;
}

.formdropf2 {
  background-color: white;
  border-color: white;
}

.formdropf2:hover {
  background-color: white;
  border-color: white;
}

.formdropf2:focus {
  background-color: white;
  border-color: white;
  box-shadow: none;
}

.fIconeStyleAction {
  margin: 10px !important;
  padding: 2px !important;
  width: auto !important;
}

.dropitemsFont {
  font-size: 14px;
}

.widthdrop {
  height: 270px;
  overflow: auto;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #949494;
}

.iconpositionf2 {
  position: fixed;
  width: 60px;
  right: 0;
  bottom: 50px;
  z-index: 1000;
}

.bottomannulmarg {
  margin-bottom: 0 !important;
}

.clearfix {
  clear: both;
  overflow: hidden;
}

app-spinner-layouts {
  position: absolute;
  top: calc((100% / 2) - (100px / 2));
  left: calc((100% / 2) - (100px / 2));
  z-index: 1024;
}

.required-field {
  border: 1px solid #dc3545 !important;
}

.example-full-width {
  width: 100%;
}

span.filename {
  padding: 0 0 0 23px !important;
  color: #00b6d7 !important;
  position: absolute;
  top: -1px;
}

.selection-custom .mat-form-field-wrapper {
  padding-bottom: 0;
}
.selection-custom .mat-form-field-wrapper .mat-form-field-infix {
  padding: 0;
  border-top: 0;
}
.selection-custom .mat-form-field-wrapper .mat-form-field-underline {
  background-color: transparent;
  display: none;
}

html {
  scroll-behavior: smooth;
}

/* Style Tab Params */
.mat-tab-group {
  margin-bottom: 48px;
}

.mat-tab-label-content {
  /*color: #A1AEB7;*/
  font-size: 16px;
  font-weight: 400;
}

.params-menu a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #828282;
}
.params-menu a:hover {
  cursor: pointer;
}

/*  Style form modal */
.title-form-modal {
  font-size: 16px;
  color: #00b6d7;
  padding: 0 13px;
  margin: auto 0;
}

.switch-form-modal {
  display: flex;
  justify-content: flex-end;
}

.form-modal-params {
  height: 410px;
}

.action-form-modal-spec {
  width: 100%;
  display: flex !important;
  justify-content: center;
  margin: 23px 0;
}

.action-modal-spec {
  width: 150px;
  margin: 0 15px;
}

.barre-heading:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  transition: 0.3s;
}

.mat-dialog-container {
  padding: 24px !important;
}

.mat-dialog-actions {
  margin-top: 15px;
  border-top: 1px solid #f1f1f1;
}

.mat-custom-table th:last-child {
  width: 10%;
  text-align: center;
}

.section-head-modal {
  display: flex;
  align-items: center;
  padding: 0 0 22px;
  border-bottom: 1px solid #f1f1f1;
  justify-content: space-between;
}

.delete-list {
  margin: 5px 10px;
  color: #00b6d7;
}
.delete-list:hover {
  color: #ff7070;
}

.table_width_30 {
  width: 30%;
}

.table_width_70 {
  width: 70%;
}

.table_width_100 {
  width: 100%;
}

.main-scroller {
  height: 84vh;
  overflow: hidden;
}
.main-scroller .left, .main-scroller .right {
  height: 100%;
  overflow: auto;
}
.main-scroller .card-bg {
  margin-top: 0;
}

.container_scoller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 82vh;
  overflow: hidden;
}
.container_scoller .card-bg {
  margin-top: 0;
}
.container_scoller .cursor-line {
  width: 30px;
  max-height: 100%;
  z-index: 3;
}
.container_scoller .cursor-line .grab {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  top: 20%;
  cursor: grab;
}
.container_scoller .cursor-line .grab.ui-draggable-dragging {
  cursor: grabbing !important;
}
.container_scoller .cursor-line .grab::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  font-size: 20px;
  left: 0px;
  background-image: url("./../images/svg/finger-point.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.container_scoller .form-container {
  flex: 0 0 25%;
  max-width: 25%;
  justify-content: space-between;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 0 15px;
  background: #ffffff;
}
.container_scoller .form-container .form-spacer {
  height: 100%;
  width: 100%;
}
.container_scoller .form-container label {
  font-weight: 500;
}
.container_scoller .prev-container {
  flex: 0 0 70%;
  max-width: 70%;
  justify-content: space-between;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 0 15px;
  background: #ffffff;
  margin-left: 15px;
}
.container_scoller .prev-container-full-width {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.container_scoller .ref {
  cursor: pointer;
}

.focused-action {
  border-bottom: 4px solid #00b6d7;
  box-shadow: none;
}

.focused-action:focus {
  border-bottom: 4px solid #00b6d7;
  box-shadow: none;
}

.focused-action:active {
  border-bottom: 4px solid #00b6d7;
  box-shadow: none;
}

.focused-action.radio-parent {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border: 3px solid #00b7d786 !important;
  box-shadow: none;
}

.focused-action.radio-parent:focus {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border: 3px solid #00b7d786 !important;
  box-shadow: none;
}

.focused-action.radio-parent:active {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border: 3px solid #00b7d786 !important;
  box-shadow: none;
}

.radio-parent .focused-action {
  border-bottom: 1px dashed #00b6d7 !important;
  box-shadow: none;
}

.focused-action.scroller-clickable.btn {
  background-color: #00b6d7;
  border: none !important;
  box-shadow: none;
  animation: debounce_ 1.1s ease-in-out alternate;
  border-radius: 4px;
}

.focused-action.scroller-clickable.btn:focus {
  background-color: #00b6d7;
  border: none !important;
  box-shadow: none;
  animation: debounce_ 1.1s ease-in-out infinite alternate;
}

.focused-action.scroller-clickable.btn:active {
  background-color: #00b6d7;
  border: none !important;
  box-shadow: none;
  animation: debounce_ 1.1s ease-in-out infinite alternate;
}

.focused-prev {
  background-color: #00b6d714;
  padding: 3px 16px 0;
  border: 2px solid #00b6d7 !important;
  border-radius: 5px;
}
.focused-prev .val-auto-complet, .focused-prev .val-pre-remplie, .focused-prev .val-pre-remplie2 {
  border: none !important;
}

.focused-prev.focused-prev-table {
  border: none;
  border: 2px solid #00b6d7;
  border-radius: 2px;
}

.focused-prev.focused-prev-content {
  border: none !important;
  border: 2px solid #00b6d7 !important;
  padding: 5px !important;
  background-color: #00b6d714;
  border-radius: 5px;
}

.sclr:focus {
  border-bottom: 4px solid #00b6d7;
  box-shadow: none;
  background-color: #f4f7fc;
  border-radius: 1px;
}

.btn-focus {
  border: none !important;
  outline: none;
  color: #fff;
  background: #00b6d7;
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.btn-focus:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border: none !important;
  box-shadow: none !important;
}

.btn-focus:active {
  color: #00b6d7;
}

.btn-focus:focus:before {
  opacity: 1;
  border: none !important;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.focused-form-check {
  display: flex;
  margin-top: 12px;
}

.focused-form-check:focus::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid #00b6d7;
  left: 44px;
  position: absolute;
  border-radius: 3px;
  animation: animleft 0.4s linear infinite;
}

@keyframes animleft {
  0% {
    left: 50px;
  }
  50% {
    left: 40px;
  }
  100% {
    left: 50px;
  }
}
.btn-modalite {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  background: #00b6d7;
  border-radius: 4px;
  width: 100%;
}
.btn-modalite:hover {
  color: #ffffff;
  opacity: 0.8;
}

.edossier-content h1 {
  margin: 15px 35px;
  line-height: 50px;
}
.edossier-content h3 {
  margin-top: 15px;
}

.btn_Actionalign {
  display: inline-flex;
}

.i-alignf {
  text-align: center;
}

.footer-action-fixed {
  z-index: 1000;
}

.legende-logo_ {
  font-style: normal;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 13px;
  text-align: center;
  color: #c79999 !important;
}
.legende-logo_ hr {
  border-bottom: 1px dotted #4f4f4f;
  width: 15%;
  margin: 7px auto;
}

caption {
  caption-side: top;
  align: left;
}

.mat-form-field-underline {
  background-color: transparent !important;
}

button#annuler, button#cancel {
  background: #e0e0e0 !important;
  color: #828282 !important;
  transition: all 0.4s ease-in-out !important;
  margin-top: 20px;
}
button#annuler:hover, button#cancel:hover {
  background: #00b6d7;
  color: #ffffff;
}

button#valider, button#confirm {
  background: #00b6d7;
  color: #fff !important;
  transition: all 0.4s ease-in-out !important;
  border-radius: 4px;
  margin-top: 20px;
}
button#valider:hover, button#confirm:hover {
  color: #F4F7FC;
  transition: 0.4s;
}

.custom-container .mat-dialog-container {
  border-radius: 4px !important;
  background: none !important;
}

.custom-container {
  background: none !important;
}

.otpblocksucces {
  width: 120px;
  height: 120px;
  margin: 10px auto 0;
  background-image: url("./../images/svg/tick.svg");
}

.otpblockfail {
  width: 120px;
  height: 120px;
  margin: 10px auto 0;
  background-image: url("./../images/svg/cancel.svg");
}

.style-modal-Upload .ngx-file-drop__drop-zone {
  border: none !important;
  border-radius: 5px;
  height: 100px;
  margin: auto;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-background {
  background-color: #00b6d7 !important;
}

.event-none {
  color: #a1aeb7 !important;
}

/* Disabled pointer-events on <a> </a> */
.disabled-link {
  pointer-events: none;
  cursor: default;
}

.background-icone-eyes {
  border: none;
  background-color: #00b6d7;
  padding: 4px 8px 3px 8px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  margin: 0px 10px;
}
.background-icone-eyes .armp-eyeff {
  color: white;
  font-size: 13px;
}

.position {
  position: fixed;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
}

.form-label-cust {
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 8px 12px;
  color: #9e9e9e;
}

.btn-danger-armp {
  background-color: #ff4b4b;
  color: #fff;
}
.btn-danger-armp:hover {
  background: #d43c3c;
  color: #F4F7FC;
  transition: 0.4s;
}

/* sometimes, we do'nt need to show material table headers */
.table-no-header thead {
  display: none;
}

/************* Loader Style ****************/
.armp-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.armp-loader:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 15px solid #fff;
  border-color: #00b6d7 transparent #00b6d7 transparent;
  animation: armp-loader 1.2s infinite;
}

@keyframes armp-loader {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.search-list {
  max-width: 280px;
  border: 1px solid #00b6d7;
  box-sizing: border-box;
}

.icone-impression-form {
  color: white;
  font-size: 15px !important;
}

.the-legend {
  border-style: none;
  border-width: 0;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  width: auto;
  padding: 0 10px;
}

.the-fieldset {
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 4px;
}

.background-accordion_ {
  background: transparent;
}
.background-accordion_ .btn {
  color: #4f4f4f;
}
.background-accordion_ .btn:hover {
  text-decoration: none;
  color: #4f4f4f;
}
.background-accordion_ .btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.e-management.statistique {
  display: flex;
  justify-content: space-between;
}
.e-management.statistique .recherche {
  width: 25%;
}
.e-management.statistique .resultat-recherche {
  width: 73%;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

div.dropdown-submenu:after {
  right: 20px;
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
  top: 5px;
  position: absolute;
}

.dropdown-item a {
  color: #828282;
}

.dropdown-item:hover a, .dropdown-item a:hover {
  color: #ffffff;
  text-decoration: none;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.sub-dropdown {
  top: 0 !important;
}

.table-static-model {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0px;
  min-width: 381px;
}
.table-static-model thead tr th {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border: 1px solid #dee2e6;
}
.table-static-model tbody tr td {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #dee2e6;
}
.table-static-model tfoot tr td {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #dee2e6;
}

.scrollBbar {
  overflow: auto;
}

.border-none {
  border: none !important;
}

.view-more-accordion {
  text-align: right;
}
.view-more-accordion button {
  color: #00b6d7;
}
.view-more-accordion button:hover {
  text-decoration: underline;
  color: #00b6d7;
}
.view-more-accordion button:focus {
  box-shadow: none;
  text-decoration: underline;
}

.custom-container-modal {
  max-width: 80%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 24px;
  margin-top: -1px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  display: none;
}

.main-container-spinner {
  position: relative;
}

#iddd {
  overflow: visible !important;
  max-width: 100%;
}

.heightModalCard {
  height: 100%;
}

.tox-tinymce-aux {
  z-index: 1 !important;
}

.h-33 {
  height: 43px;
}

.bg-table {
  background: #f2f2f2;
}

.rectif-paddingBtnIcone {
  padding: 0.375rem 0.75rem !important;
}

.j-content-form {
  justify-content: end;
}

.formBasket {
  color: #ffffff;
  font-size: 17px;
  cursor: pointer;
  margin: 0 5px;
  text-decoration: none;
}
.formBasket:hover {
  color: #ffffff;
  text-decoration: none;
}

.iconifyStyle {
  font-size: 18px;
  top: 8px;
}
.iconifyStyle .iconify {
  vertical-align: baseline;
}

@font-face {
  font-family: "icoArmp";
  src: url("./../fonts/iconArmp/icoArmp.eot?adf0hx");
  src: url("./../fonts/iconArmp/icoArmp.eot?adf0hx#iefix") format("embedded-opentype"), url("./../fonts/iconArmp/icoArmp.ttf?adf0hx") format("truetype"), url("./../fonts/iconArmp/icoArmp.woff?adf0hx") format("woff"), url("./../fonts/iconArmp/icoArmp.svg?adf0hx#icoArmp") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=armp-],
[class*=" armp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icoArmp" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.armp-arrow-back:before {
  content: "";
}

.armp-secteur-public:before {
  content: "";
}

.armp-sectuer-prive:before {
  content: "";
}

.armp-societe-civile-vip:before {
  content: "";
}

.armp-logout:before {
  content: "";
}

.armp-notification:before {
  content: "";
}

.armp-gear:before {
  content: "";
}

.armp-user:before {
  content: "";
}

.armp-icon-plus:before {
  content: "";
}

.armp-ico-delete-table:before {
  content: "";
}

.armp-ico-diffusion-table:before {
  content: "";
}

.armp-ico-edit-table:before {
  content: "";
}

.armp-ico-file-table:before {
  content: "";
}

.armp-ico-info-table:before {
  content: "";
}

.armp-ico-pdf-table:before {
  content: "";
}

.armp-ico-refresh-table:before {
  content: "";
}

.armp-calendar:before {
  content: "";
}

.armp-arrow-down:before {
  content: "";
}

.armp-times:before {
  content: "";
}

.armp-portal:before {
  content: "";
}

.armp-config:before {
  content: "";
}

.armp-finger-point:before {
  content: "";
}

.armp-hand:before {
  content: "";
}

.armp-svg:before {
  content: "";
}

.armp-svgtwo:before {
  content: "";
}

.armp-eyeff:before {
  content: "";
}

.armp-cart-fill:before {
  content: "";
}

.armp-shopping-cart:before {
  content: "";
}

.armp-floppy:before {
  content: "";
}

.armp-clipboard-edit:before {
  content: "";
}

.armp-pen:before {
  content: "";
}

.armp-success-pv:before {
  content: "";
}

.armp-publish:before {
  content: "";
}

.armp-checkmark:before {
  content: "";
}

.armp-warning:before {
  content: "";
}

.armp-alert:before {
  content: "";
}

.armp-warning1:before {
  content: "";
}

.armp-check-alt:before {
  content: "";
}

.armp-cross:before {
  content: "";
}

.armp-cross-circle:before {
  content: "";
}

.armp-download:before {
  content: "";
}

.armp-newspaper:before {
  content: "";
}

.armp-book:before {
  content: "";
}

.armp-pencil:before {
  content: "";
}

.armp-eye:before {
  content: "";
}

.armp-evaluate-design:before {
  content: "";
}

.armp-mail-reply-all:before {
  content: "";
}

.armp-clipboard:before {
  content: "";
}

.table-actions li span.event-none {
  color: #a1aeb7 !important;
}

.btn-add.btn-modal {
  width: auto;
}

.head-tranche {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-tranche {
  margin: 0 !important;
  padding: 13px;
  color: #505050;
}

.contenueAccordionF {
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-top: 0;
}

a.ppmdtlstyleModal {
  padding: 0 37px;
  color: #00b6d7;
}