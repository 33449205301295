// responsive specifié pour Editor tinyMCE
@media (min-width: 1200px) {
        // Editor TinyMCE RESPONSIVE
            .ck.ck-editor{
                max-width: 960px;   
            }

}
/*PC bas résolution , Tablette , Mobile*/

@media (max-width: 1199.98px) {
    .table-spec {
        .main-spinner {
            overflow: auto;
            max-height: 600px;
            table.mat-table {
                min-width: 1070px!important;
            }
        }
    }
    .table-responsive {
        overflow: auto;
        max-height: 600px;
        table.mat-table {
            min-width: 1070px!important;
        }
    }
    .show-egp p {
        margin-bottom: 20px!important;
    }
    .show-egp p,
    .show-egp h3 {
        margin-bottom: 20px!important;
    }
    .show-egp span {
        position: inherit!important;
    }
    .navbar-collapse.collapse.show {
        background: #00b6d7;
        padding: 10px 15px;
        box-shadow: 0px 4px 16px -10px #292929;
    }
    .navbar-toggler {
        position: absolute;
        right: 0;
        /* background: #ffffff;*/
    }
    .nav-item {
        text-align: center;
        width: 200px;
        margin: 0 auto!important;
        &.dropdown.dmenu {
            text-align: center;
        }
    }
    header nav .navbar-collapse .option-part {
        display: flex;
        align-items: center;
        justify-content: center;
        .armp-icon {
            margin: 0 8px!important;
        }
    }
    // responsive formulaire rapport-justificative
    .flex-form {
        display: block !important;
        .form-control {
            width: 100% !important;
        }
    }
    .formflex-inflex {
        width: 100% !important;
        .custom-input {
            width: 80% !important;
        }
        .selectWidth {
            width: 120px !important;
        }
    }
    .responsive-datePicker {
        .icon-date-picker:after {
            top: 36px !important;
        }
        .arrow-select:after {
            top: 41px !important;
        }
        .formflex-inflex {
            .arrow-select:after {
                top: 13px !important;
            }
        }
    }
    .editorWidth {
        width: 100% !important;
    }

    // Responsive des chart graphique dans e-management
    .minWidth_Chart{
        min-width: 700px;
    }

    // modification top arrow-select en responsive
    .arrowS-Edit_top:after {
        top: 50px !important;
    }

    // responsive menu

    // menu parametre
    .w-params{
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
}


/*PC bas résolution*/

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card.card-portail {
        width: 100%!important;
    }

    // Editor TinyMCE RESPONSIVE
        .ck.ck-editor{
            max-width: 790px;   
        }
}



/*Tablette*/

@media (min-width: 768px) and (max-width: 991.98px) {
    .show-egp p,
    .show-egp h3 {
        margin-bottom: 20px!important;
    }
    .show-egp span {
        position: inherit!important;
    }
    .login {
        width: 60%;
        margin: 0 auto;
    }
    // e-management responsive
    .e-management {
        &.statistique {
            display: block;
            justify-content: space-between;
            .recherche {
                width: 100%;
            }
            .resultat-recherche {
                width: 100%;
            }
        }
    }
    .icon-date-picker:after {
        top: 35px !important;
    }
    // .e-management-Contentaccordion {
    //     max-height: none;
    //     overflow: auto;
    // }
    // reponsive boutton pour tablette
    .responsiveBtn {
        display: block !important;
        .btn {
            width: 100% !important;
            margin: 0 0 10px 0px !important;
        }
        .customResponsive-btn1 {
            width: 115px !important;
        }
        .customResponsive-btn2 {
            justify-content: left;
            .btn {
                width: 115px !important;
                margin-left: 20px;
            }
        }
        .longTxt-btn{
            width: auto !important;
            height: auto;
        }
        .none-margin{
            margin: 0 !important;
        }
        .customResponsive-secondbtn{
            margin-left: 10px !important;
        }
    }
    .card-action .btn {
        margin-bottom: 10px!important;
    }

    // sous-menu dans main-header
    // sous-menu e-evaluation
    .sub-eval {
        left: 152px !important;
        top: 65px !important;
    }
    .sub-eval2 {
        left: 152px !important;
        top: 27px !important;
    }
    // sous menu e-contrat
    .sub-contrat{
        left: 180px;
        top: 125px !important;
    }
    .sub-contrat2{
        left: 180px !important;
        top: 28px !important;
    }
    // sous menu e-dossier
    .sub-dossier{
        top: 25px !important;
        left: 130px;

    }
    .sub-dossier2{
        top: 25px !important;
        left: 130px;

    }
    // Editor TinyMCE RESPONSIVE
        .ck.ck-editor{
            max-width: 599px;
        }

    // Responsive pour footer
    footer {
        .d-flex {
            .left-foot {
                flex: 0 0 38% !important;
                font-size: 12px !important;
            }
            .center-foot {
                flex: 0 0 29% !important;
                font-size: 12px !important;
            }
            .right-foot {
                flex: 0 0 33% !important;
                font-size: 12px !important;
            }
        }
    }

}


/*Mobile*/

@media (min-width: 320px) and (max-width: 767.98px) {
    // e-management responsive
    .e-management {
        &.statistique {
            display: block;
            justify-content: space-between;
            .recherche {
                width: 100%;
            }
            .resultat-recherche {
                width: 100%;
            }
        }
    }
    // Responsive des chart graphique dans e-management
    .minWidth_Chart2{
        min-width: 500px;
    }
    // reponsive boutton pour tablette
    .responsiveBtn {
        display: block !important;
        .btn {
            width: 100% !important;
            margin: 0 0 10px 0px !important;
        }
        .customResponsive-btn1 {
            width: 115px !important;
        }
        .customResponsive-btn2 {
            justify-content: left;
            .btn {
                width: 115px !important;
                margin-left: 10px;
            }
        }
        .longTxt-btn{
            width: auto !important;
            height: auto;
        }
        .none-margin{
            margin: 0 !important;
        }
        .customResponsive-secondbtn{
            margin-left: 10px !important;
        }
    }
    .icon-date-picker:after {
        top: 35px !important;
    }
    .card-action .btn {
        margin-bottom: 10px!important;
    }

    // 2em form button responsive mode
    .form2_responsiveBtn {
        display: block !important;
        margin-bottom: 0 !important;
        .btn {
            width: 100% !important;
            margin: 0 0 10px 0px !important;
        }
    }
    .calc-height-matContentModal{
        height: calc(100% - 152px - 42px) !important;
    }

    // sous-menu dans main-header mobile
    .nav-item {
        width: 80%;
    }
    .responsive-dropdownM{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
    // sous-menu e-evaluation
    .sub-eval {
        top: 65px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: fit-content;
    }
    .sub-eval2 {
        top: 27px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: fit-content;
    }
    // sous menu e-contrat
    .sub-contrat{
        top: 125px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: fit-content;
    }
    .sub-contrat2{
        top: 28px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: fit-content;
    }
    // sous menu e-dossier
    .sub-dossier{
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: fit-content;
        top: 30px !important;

    }
    .sub-dossier2{
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: fit-content;
        top: 30px !important;
    }
    // responsive MENU Parametrage
    .params-responsive-form{
        display: block !important;
        div{
          max-width: none !important;
          padding: 0;
          input{
            width: 100% !important;
          }
          select{
            width: 100%;
          }
        }
      } 
    }
// responsive form specifié footer
@media (min-width: 625px) and (max-width: 767.98px) {
        // Responsive pour footer
        footer {
            height: 35px !important;
            .d-flex {
                .left-foot {
                    flex: 0 0 38% !important;
                    font-size: 10px !important;
                }
                .center-foot {
                    flex: 0 0 29% !important;
                    font-size: 10px !important;
                }
                .right-foot {
                    flex: 0 0 33% !important;
                    font-size: 10px !important;
                }
            }
        }   
    }
@media (max-width: 624px) {
    // Responsive pour footer
    footer {
        height: 45px !important;
        .d-flex {
            .left-foot {
                flex: 0 0 38% !important;
                font-size: 10px !important;
            }
            .center-foot {
                flex: 0 0 29% !important;
                font-size: 10px !important;
                text-align: center !important;
            }
            .right-foot {
                flex: 0 0 33% !important;
                font-size: 10px !important;
            }
        }
    }   
}



// Responsive modication specifié

@media (max-width: 320px) {
    .responsive-mobile {
        text-align: center;
        margin-top: 20px;
    }
    .the-legend {
        margin: auto;
    }
    // reponsive boutton pour tablette
    .responsiveBtn {
        display: block !important;
        .btn {
            width: 100% !important;
            margin: 0 0 10px 0px !important;
        }
        .customResponsive-btn1 {
            width: 115px !important;
        }
        .customResponsive-btn2 {
            justify-content: left;
            .btn {
                width: 115px !important;
                margin-left: 20px;
            }
        }
        .longTxt-btn{
            width: auto !important;
            height: auto;
        }
        .none-margin{
            margin: 0 !important;
        }
        .customResponsive-secondbtn{
            margin-left: 10px !important;
        }
    }
    .card-action .btn {
        margin-bottom: 10px!important;
    }
}

// responsive ecran specifié tynyMCE
@media (min-width: 565px) and (max-width: 767.98px) {
    // Editor TinyMCE RESPONSIVE
        .ck.ck-editor{
            max-width: 429px;
        }
}
@media (max-width: 564px) {
        // Editor TinyMCE RESPONSIVE
            .ck.ck-editor{
                max-width: 229px !important;
            }
}
// fin responsive ecran specifié tynyMCE

// responsive button ajout au dessus de chaque liste de tableau dans chaque module
@media (max-width: 575px) {
    .m-topBottom-responsiveBtn{
        margin: 20px 0 !important;
    }
    .j-content-form{
        justify-content: left !important;
    }
}

@media (max-width: 478px) {
    .date-picker_other:after{
        top: 46px !important;
    }
}

// responsive modal chart
@media (max-width: 979px) {
    .performance {
        .content-modal-height {
            height: calc(100% - 120px) !important;
            max-height: 90%;
            overflow: auto !important;
        }
        .width-close-modal {
            height: 50px !important;
            width: 100%;
        }
        .h-legendModal {
            height: 70px !important;
        }
    }
}

@media (max-width: 931px) {
    // responsive button modal
    .modal-content-responsive-form{
        height: calc(100% - 43px - 107px) !important;
    }
    .responsive-btn-modalForm{
        display: block !important;
        .btn{
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
    .h-33{
        h4{
            padding: 0;
        }
    }
    .responsive-formulaireModal{
        display: block;
        div{
            max-width: none !important;
            padding: 0;
        }
    }
    .responsive-formulaireModal2{
        display: block;
        div{
            max-width: none;
        }
    }
}

@media (max-width: 890px){
    // titre avec select et bouton dans un modal
    .responsiveForm-modal{
    display: block !important;
    div{
      margin: 1rem 0;
      .form-select{
        width: 100%;
      }
    }
    .btn{
      width: 100%;
      margin-bottom: 1rem !important;
    }
  }
}
// responsive accordion e-management
@media (max-width: 709px) {
    .responsive-radioAccordion{
        display: flex;
        align-items: baseline;
    }
}
