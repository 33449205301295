/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


/* styles scrollbar */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 165, 165); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(181, 181, 181); 
}

@media screen and (min-width: 768px) {
  .icon-date-picker:after {
   /* top: 7px !important;*/
  }
}