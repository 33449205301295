@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";


// animation keyframes [start]
@keyframes debounce_ {

	from {
		transform: scale(1);
	}

	to {
		transform: scale(0.95);
	}

}
// animation keyframes [end]

// Bootstrap customization
.text-danger, .text-warning{
	font-size: 12px !important;
	padding: 3px 5px !important;
}

* {
	font-family: "Ubuntu", sans-serif;
	outline: none;
}

html,
body {
	background: $color-main-bg;
}
.auth-container {
	max-width: 800px;
	margin-top: 50px;
	h3 {
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: $color-blue;
		text-align: center;
		margin-top: 25px;
	}
	p {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: #a1aeb7;
		text-align: center;
		padding: 0 20px;
	}
	.row {
		background: $color-white;
		border-radius: 0px 4px 4px 0px;
		> div {
			padding: 0;
		}
		form {
			margin: 15px 50px;
			.form-control {
				font-family: "Ubuntu", sans-serif;
			}
			.form-label {
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 16px;
				color: #505d68;
				margin: 0;
				margin-bottom: 10px;
				font-family: "Ubuntu", sans-serif;
			}
			.form-check {
				a {
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 16px;
					color: $color-blue;
					text-decoration: none;
					text-align: center;
					display: block;
					transition: underline 0.4s ease-in-out;
				}
				a:hover {
					text-decoration: underline !important;
				}
			}
			hr {
				background: $color-main-bg;
				margin: 25px 0px;
			}
			.btn {
				width: 100%;
				box-shadow: 0px 10px 10px rgba(0, 182, 215, 0.2);
				border-radius: 50px;
				margin: 5px 0px;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 18px;
				height: 45px;
			}
			.btn-send {
				background: $color-blue;
				color: $color-white;
				transition: all 0.6s ease-in-out !important;
			}
			.btn-send:hover {
				background: $color-main-bg;
				color: $color-primary;
			}
			.btn-reset {
				background: $color-main-bg;
				color: $color-primary;
				transition: all 0.6s ease-in-out !important;
			}
			.btn-reset:hover {
				background: $color-blue;
				color: $color-white;
			}
		}
	}
}

// generale styles
.main-page {
	margin-bottom: 100px;
	h1 {
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 30px;
		color: $color-primary;
		margin: 15px 0 0;
	}
	.breadcrumbs {
		padding: 0;
		margin: 0;
		display: flex;
		margin-top: 5px;
		li {
			list-style-type: none;
			margin-right: 15px;
			position: relative;
			&::after {
				content: "/";
				position: absolute;
				top: 0;
				right: -10px;
				width: 5px;
				height: 20px;
				color: $color-primary;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
			a {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 18px;
				color: $color-primary;
				text-decoration: none;
			}
		}
	}
	.card-bg {
		background: $color-white;
		border-radius: 4px;
		padding: 20px;
		margin-top: 25px;
		font-family: "Ubuntu", sans-serif;
		h3 {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 18px;
			color: $color-blue;
			margin: 10px 0px 15px;
		}
	}
}
.card-action {
	text-align: center;
	width: 100%;
	.btn {
		border-radius: 4px;
		width: 194px;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		margin: 0 15px;
		height: 35px;
	}
	.btn-cancel-form {
		background: #e0e0e0!important;
		color: $color-secondary!important;
		transition: all 0.4s ease-in-out!important;
	}
	.btn-cancel-form:hover {
		background: #00b6d7;
		color: #ffffff;
	}
	.btn-send-form {
		background: $color-blue;
		color: $color-white;
		transition: all 0.4s ease-in-out;
	}
	.btn-send-form:hover {
		background: #0796af;
		color: #F4F7FC;
		transition: 0.4s;
	}
}

// Customez table material
.mat-custom-table {
	width: 100%;
	font-family: "Ubuntu", sans-serif;
	thead {
		tr {
			height: 46px;
			th {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: $color-primary;
				border-bottom: none;
				padding: 0 12px;
			}
		}
	}
	tbody {
		tr {
			height: 46px;
			&:nth-child(2n - 1) {
				td {
					background: #f9f9f9;
				}
			}
			td {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #a1aeb7;
				border-bottom: none;
				padding: 5px 10px;
				.form-control {
					background: #ffffff;
				}
			}
		}
	}
}

// Affichage element form
.main-show-element {
	label {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: $color-primary;
	}
	span {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: $color-secondary;
	}
}

// btn add data - table
.btn-add{
	background: $color-blue;
	/*box-shadow: 0px 10px 10px rgba(0, 182, 215, 0.2);*/
	border-radius: 4px;
	//width: 100%;
	height: 35px;
	border: none;
	color: $color-white;
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	margin-left: auto;
	position: relative;
	margin-top: 0.3rem;
	transition: all 0.4s ease-in-out;
	&:hover {
		/*color: #4f4f4f;
		background: #f4f7fc;
		border: 1px solid $color-blue;*/
		background: #0796af;
		color: #F4F7FC;
		transition: 0.4s;
	}
	.armp-icon-plus {
		font-size: 12px;
		margin-right: 5px;
	}
}

// Filter table
.main-table-filter {
	position: relative;
	top: 0;
	height: 80px;
	.arrow-select {
		width: 100%;
		max-width: 270px;
	}
	select {
		display: block;
		height: 35px;
		background: $color-main-bg;
		border: 1px solid $color-blue;
		box-sizing: border-box;
		border-radius: 4px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #a1aeb7;
		padding: 0 35px 0 10px;
		width: 100%;
		max-width: 270px;
	}
}
    // modification top arrow-select en responsive
    .arrowS-Edit2_top:after {
        top: 50px !important;
    }
	.arrow-NoneLabel:after{
		top: 13px !important;
	}

.form-select{
	display: block;
	height: 35px;
	background: $color-main-bg;
	border: 1px solid $color-blue;
	box-sizing: border-box;
	border-radius: 4px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #a1aeb7;
	padding: 0 35px 0 10px;
	width: 270px;
}


.table-actions {
	display: flex;
	padding: 0;
	margin: 0;
	li {
		margin: 0px 10px;
		list-style-type: none;
		color: $color-blue;
		position: relative;
		.table-icons,
		span {
			position: relative;
			top: 8px;
			font-size: 18px;
			cursor: pointer;
			color: inherit;
			text-decoration: none;
			&:hover {
				color: inherit;
			}
		}
		.switch {
			position: relative;
			top: 2px;
		}
	}
	li.align-icon span{
		position: relative;
		top: 0px;
	}
}
.table-action-ajout {
	display: flex;
	padding: 0;
	margin-left: 0;
	li {
		margin: 0px 5px;
		list-style-type: none;
		color: $color-white;
		position: relative;
		span {
			position: relative;
			top: 8px;
			font-size: 16px;
			cursor: pointer;
			background: $color-blue;
			border-radius: 50%;
			padding: 4px;
		}
		label {
			position: relative;
			top: 8px;
			font-size: 14px;
			cursor: pointer;
			color: $color-blue;
		}
		.switch {
			position: relative;
			top: 8px;
		}
	}
}

// Custom-Modal-Material
.cdk-overlay-container {
	position: absolute;
	width: 100%;
	height: 100%;
	.cdk-overlay-backdrop {
		background: rgb(0 0 0 / 0.6);
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 1;
	}
	.cdk-global-overlay-wrapper {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		.cdk-overlay-pane {
			margin: auto;
			// margin-top: 5vh;
			background: $color-white;
		}
	}
}
// Pour les selecteurs
.arrow-select {
	position: relative;
	&:after {
		content: "\e911";
		font-family: "icoArmp";
		font-size: 9px;
		position: absolute;
		top: 40px;
		right: 5px;
		width: 20px;
		height: 20px;
		color: $color-blue;
		pointer-events: none;
	}
	select {
		appearance: none;
	}
}
:host ::ng-deep {
	app-btn-ajout-offre {
		float: right;
		margin-bottom: 15px;
	}
}

h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	color: $color-blue;
}
.mat-typography {
	.form-label {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: $color-primary;
	}
	.form-control {
		background: $color-main-bg;
		border-radius: 4px;
		border: none;
		height: 35px;
	}
}

label {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: $color-primary;
}
.form-control {
	background-color: $color-main-bg;
	border-radius: 4px;
	border: none;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: $color-secondary;
	outline: 0;
}
textarea.form-control {
	height: 95px !important;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: $color-main-bg;
	pointer-events: none;
	opacity: 0.6;
	border: 1px solid #cfd1d4;
}

/* custom scrollbar by css3 */
.scroller {
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e0e0e0;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
	}
}
body,
.mat-dialog-container {
	overflow: auto;
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e0e0e0;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
	}
}
.btn-inscription {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 43px;
	color: #4f4f4f;
	background: #f4f7fc;
	box-shadow: 0px 10px 10px rgb(0 182 215 / 20%);
	border-radius: 50px;
	height: 45px;
	margin: 15px 50px;
	text-align: center;
	display: block;
	text-decoration: none;
	transition: all 0.6s ease-in-out !important;
	&:hover {
		background: $color-blue;
		color: $color-white;
		text-decoration: none;
	}
}

// Event clicke disablade
.event-none {
	pointer-events: none;
}
// Valuer de saisie automatique
.val-auto-complet {
	// display: inline-block;
	border-bottom: 1px dashed #8e8e8e;
	// min-width: 80px;
	color: rgb(62, 111, 218);
	font-weight: 500;
	// text-align: center;
}

//valeur à saisir sur les dossiers
.val-pre-remplie {
	  display: inline-block;
    /* border-bottom: 1px dashed #8e8e8e; */
    // min-width: 20px;
    /* color: #3e6fda; */
    font-weight: 500;
    text-align: center;
}

.val-pre-remplie2 {
	border-bottom: 1px dashed #8e8e8e !important;
	min-width: 80px !important;
	color: #3e6fda !important;
	font-weight: 500 !important;
}

// boutton plus dans les formulares
.add-list-btn {
	background: #00b6d7;
    color: #ffffff;
    border: 1px solid #00b6d7;
    border-radius: 50%;
	transition: all ease 0.6ms;
	padding: 2px;
}

.add-list-btn:hover{
	background: none;
}

.disablade-block {
	pointer-events: none;
	opacity: 0.2;
}
.hidden-element {
	display: none;
}

// Personalisation chckbox tableau
.styled-checkbox-armp {
	position: absolute;
	opacity: 0;
	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
		margin: 0;
	}
	& + label:before {
		content: "";
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 15px;
		height: 15px;
		background: white;
		border: 1px solid #00b6d7;
		border-radius: 2px;
	}
	&:hover + label:before {
		background: #00b6d7;
	}
	&:focus + label:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}
	&:checked + label:before {
		background: #00b6d7;
	}
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}
	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}
	&:checked + label:after {
		content: "";
		position: absolute;
		left: 2px;
		top: 7px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
			4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg);
	}
}
// with label
.icon-date-picker {
	position: relative;
	&:after {
		content: "\e910";
		font-family: "icoArmp";
		font-size: 14px;
		position: absolute;
		top: 35px;
		right: 5px;
		width: 20px;
		height: 20px;
		color: $color-blue;
		pointer-events: none;
	}
}
// top date picker responsive
.date-picker_Edit:after{
	top: 46px !important;
}
// without label
.icon-date-picker-form {
	position: relative;
	&:after {
		content: "\e910";
		font-family: "icoArmp";
		font-size: 14px;
		position: absolute;
		top: 8px;
		right: 5px;
		width: 20px;
		height: 20px;
		color: $color-blue;
		pointer-events: none;
	}
}

// shitch checkbox
.main-shitch-radio {
	line-height: 5px;
	input[type="checkbox"] {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 26px;
		height: 17px;
		background: #acb0b1;
		display: block;
		border-radius: 100px;
		position: relative;
	}
	label:after {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: 13px;
		height: 13px;
		background: #fff;
		border-radius: 90px;
		transition: 0.3s;
	}
	input:checked + label {
		background: #00b6d7;
	}
	input:checked + label:after {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}
	label:active:after {
		width: 22px;
	}
}
.align-self-end {
	align-self: flex-end;
}
.mat-dialog-content {
	height: calc(100% - 130px);
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #e0e0e0;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
	}
}
::-webkit-calendar-picker-indicator {
	position: relative;
	z-index: 2;
	right: -4px;
	opacity: 0;
	cursor: pointer;
}
.icon-times {
	position: relative;
	&:after {
		content: "\e912";
		font-family: "icoArmp";
		font-size: 14px;
		position: absolute;
		top: 7px;
		right: 5px;
		width: 20px;
		height: 20px;
		color: $color-blue;
	}
}

// debut align F
	.rectifalign_global{
		margin-bottom: 20px !important;
	}
	.widthAllF{
		width: 100%;
	}
	// .annulmarginF{
	// 	margin-top: 0 !important;
	//   }
	.annulmargin_bottomF{
		margin-bottom: 0 !important;
	}
	.displayrectificat{
		display: block !important;
	}
	.rectific_font{
		font-size: 14px !important;
		font-weight: bolder !important;
		color: #00000073 !important;
	}
	.rectific_font2{
		font-size: 14px !important;
	}
	.alignCPS_3{
		// height: 133px !important;
		margin-bottom: 25px !important;
	}

	.cpsTrx_1{
		height: 70px;
	}
	// .rectifCardF{
	// 	height: 100% !important;
	// }
	.scrollmodalModif{
		display: inline !important;
	}
	.formdropf{
		height: 50px;
	}
	.formdropf2{
		background-color: white;
		border-color: white;
	}
	.formdropf2:hover{
		background-color: white;
		border-color: white;
	}
	.formdropf2:focus{
		background-color: white;
		border-color:white;
		box-shadow: none;
	}
	.fIconeStyleAction{
		margin: 10px !important;
		padding: 2px !important;
		width: auto !important;
	}
	.dropitemsFont{
		font-size: 14px;
	}
	.widthdrop{
		height: 270px;
    	overflow: auto;
	}
// fin alignement F

.table-bordered th, .table-bordered td {
	border: 1px solid #949494;
}

// style de boutton dans les action des form f
.iconpositionf2{
    position: fixed;
    width: 60px;
    right: 0;
    bottom: 50px;
	z-index: 1000;
  }
// .iconpositionf2{
// 	position: fixed;
// 	width: 60px;
// 	right: 0;
// 	bottom: 50px;
// 	z-index: 1000;
// }
  .bottomannulmarg{
	margin-bottom: 0 !important;
  }
// style de boutton dans les action des form f
.clearfix {
	clear: both;
	overflow: hidden;
}

app-spinner-layouts {
	position: absolute;
	top: calc((100% / 2) - (100px / 2));
	left: calc((100% / 2) - (100px / 2));
	z-index: 1024;
}
// Champ obligatoire pour les champs d'un formulaire
.required-field {
	border: 1px solid #dc3545 !important;
}
// MAT AUTOCOMPLETE
.example-full-width {
	width: 100%;
}

span.filename{
	padding: 0 0 0 23px!important;
	color: #00b6d7!important;
	position: absolute;
	top: -1px;
}



//:host ::ng-deep
.selection-custom {
    .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-infix {
            padding: 0;
            border-top: 0;
        }
        .mat-form-field-underline {
            background-color: transparent;
			display: none;
        }
    }
}


// Smooth scrolling

html {
	scroll-behavior: smooth;
}


/* Style Tab Params */
.mat-tab-group {
  margin-bottom: 48px;
}
.mat-tab-label-content{
	/*color: #A1AEB7;*/
	font-size: 16px;
	font-weight: 400;
}

.params-menu a{
	font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #828282;
	&:hover{
		cursor: pointer;
	}
}


/*  Style form modal */
.title-form-modal{
	font-size: 16px;
	color: #00b6d7;
	padding: 0 13px;
	margin: auto 0;
}

.switch-form-modal{
	display: flex;
	justify-content: flex-end;
}
.form-modal-params{
	height: 410px;
}

.action-form-modal-spec{
	width: 100%;
  display: flex!important;
	justify-content: center;
	margin: 23px 0;
}
.action-modal-spec{
	width: 150px;
	margin: 0 15px;
}
.barre-heading:hover{
		background-color: #f7f7f7;
		cursor: pointer;
		transition: 0.3s;
}

.mat-dialog-container{
	padding: 24px!important;
}
.mat-dialog-actions{
	margin-top: 15px;
	border-top: 1px solid #f1f1f1;
}

.mat-custom-table {
	th {
		&:last-child {
			width: 10%;
			text-align: center;
		}
	}
}
.section-head-modal{
	display: flex;
  align-items: center;
	padding: 0 0 22px;
	border-bottom: 1px solid #f1f1f1;
	justify-content: space-between;
}

.delete-list{
	margin: 5px 10px;
	color: #00b6d7;
	&:hover{
			color: #ff7070;
	}
}

// taille de chaque colonne tableau S'il faut diviser en 2
.table_width_30{
	width: 30%;
}
.table_width_70{
	width: 70%;
}
.table_width_100{
	width: 100%;
}
// taille de chaque colonne tableau S'il faut diviser en 2






// DATA INDEXED STYLE
.main-scroller {
  height: 84vh;
  overflow: hidden;
  .left, .right {
    height: 100%;
    overflow: auto;
  }
  .card-bg {
    margin-top: 0;
  }
}

.container_scoller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 82vh;
  overflow: hidden;
	.card-bg {
    margin-top: 0;
  }
  .cursor-line{
    width: 30px;
    max-height: 100%;
		z-index: 3;
    .grab {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: relative;
      top: 20%;
      cursor: grab;
      &.ui-draggable-dragging{
        cursor: grabbing !important;
      }
      &::before{
				content: '';
        width: 20px;
				height: 20px;
				position: absolute;
				top: 10px;
				font-size: 20px;
				left: 0px;
				background-image: url('./../images/svg/finger-point.svg');
				background-repeat: no-repeat;
				background-size: 100%;
      }
    }
  }

  .form-container {
		flex: 0 0 25%;
    max-width: 25%;
    justify-content: space-between;
    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
    padding: 0 15px;
		background: #ffffff;
		.form-spacer {
      height: 100%;
      width: 100%;
    }
		label {
			font-weight: 500;
		}
  }

  .prev-container {
    flex: 0 0 70%;
    max-width: 70%;
    justify-content: space-between;
    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
    padding: 0 15px;
		background: #ffffff;
		margin-left: 15px;
  }

	.prev-container-full-width {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}

  .ref {
    cursor: pointer;
  }

}

//Default focused scroller input/trigger [start]
.focused-action {

	border-bottom: 4px solid #00b6d7;
	box-shadow: none;

}
.focused-action:focus {

	border-bottom: 4px solid #00b6d7;
	box-shadow: none;

}
.focused-action:active {

	border-bottom: 4px solid #00b6d7;
	box-shadow: none;

}
//Default focused scroller input/trigger [end]



//custom focused scroller input/trigger [start]
.focused-action.radio-parent {

	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 5px;
	border: 3px solid #00b7d786 !important;
	box-shadow: none;

}

.focused-action.radio-parent:focus {

	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 5px;
	border: 3px solid #00b7d786 !important;
	box-shadow: none;

}

.focused-action.radio-parent:active {

	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 5px;
	border: 3px solid #00b7d786 !important;
	box-shadow: none;

}

.radio-parent .focused-action{

		border-bottom: 1px dashed #00b6d7 !important;
		box-shadow: none;

}


// button
.focused-action.scroller-clickable.btn {

	background-color: #00b6d7 ;
	border: none !important;
	box-shadow: none;
	animation: debounce_ 1.1s ease-in-out alternate ;
	border-radius: 4px;

}

.focused-action.scroller-clickable.btn:focus {

	background-color: #00b6d7 ;
	border: none !important;
	box-shadow: none;
	animation: debounce_ 1.1s ease-in-out infinite alternate;

}

.focused-action.scroller-clickable.btn:active {

	background-color: #00b6d7 ;
	border: none !important;
	box-shadow: none;
	animation: debounce_ 1.1s ease-in-out infinite alternate;

}


//custom focused scroller input/trigger [start]



//Default focused previsualisation
.focused-prev {
	background-color: #00b6d714;
	padding: 3px 16px 0;
	border: 2px solid #00b6d7!important;
	border-radius: 5px;


	.val-auto-complet, .val-pre-remplie, .val-pre-remplie2{

		border: none !important;

	}

}

// Focused previsualisation customization by view type [start]
.focused-prev.focused-prev-table{
	border: none;
	border: 2px solid #00b6d7;
	border-radius: 2px;
}

.focused-prev.focused-prev-content{
	border: none !important;
	border: 2px solid #00b6d7 !important;
	padding: 5px !important;
	background-color: #00b6d714;
	border-radius: 5px;
}

// Focused previsualisation customization by view type [end]

.sclr:focus {
	border-bottom: 4px solid #00b6d7;
	box-shadow: none;
	background-color: #f4f7fc;
	border-radius: 1px;
}

// DEBUT Style Focused button form
.btn-focus {
	border: none!important;
	outline: none;
	color: #fff;
	background: #00b6d7;
	cursor: pointer;
	position: relative;
	z-index: 0;
}

.btn-focus:before {
	content: '';
	// background: linear-gradient(45deg, #00b6d7, #76d3e4, #22beda, #88e5f5);
	position: absolute;
	top: -2px;
	left:-2px;
	background-size: 400%;
	z-index: -1;
	width: calc(100% + 4px);
	// height: calc(100% + 4px);
	// animation: glowing 20s linear infinite;
	opacity: 0;
	transition: opacity .3s ease-in-out;
	border: none!important;
	box-shadow: none!important;
}

.btn-focus:active {
	color: #00b6d7;
}


.btn-focus:focus:before {
	opacity: 1;
	border:none!important;
}

@keyframes glowing {
	0% { background-position: 0 0; }
	50% { background-position: 400% 0; }
	100% { background-position: 0 0; }
}
// FIN Style Focused button form

// Début Style focused switch

.focused-form-check{
	display: flex;
  margin-top: 12px;
}
.focused-form-check:focus::before{
	content: "";
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-right: 10px solid #00b6d7;
	left: 44px;
	position: absolute;
	border-radius: 3px;
	animation: animleft 0.4s linear infinite;
}

@keyframes animleft {
	0% { left:50px; }
	50% { left:40px; }
	100% { left:50px; }
}
// Fin Style Focused switch
.btn-modalite {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #ffffff;
	background: #00b6d7;
	border-radius: 4px;
	width: 100%;
	&:hover {
		color: #ffffff;
		opacity: 0.8;
	}
}
.edossier-content {
	h1 {
		margin: 15px 35px;
		line-height: 50px;
	}
	h3 {
		margin-top: 15px;
	}
}
// Alignement des autre icone button dans les tableaux
.btn_Actionalign{
	display: inline-flex;
}
.i-alignf{
	text-align: center;
}
// FIN Alignement des autre icone button dans les tableaux

// style navigation celon le position du footer-action
.footer-action-fixed {
z-index: 1000;
}

// style du titre "MINISTÈRE DE L'ECONOMIE ET DES FINANCES AUTORITE DE REGULATION DES MARCHES PUBLICS"
.legende-logo_ {
	font-style: normal;
	font-weight: normal !important;
	font-size: 12px !important;
	line-height: 13px;
	text-align: center;
	color: #c79999 !important;
	hr {
		border-bottom: 1px dotted #4f4f4f;
		width: 15%;
		margin: 7px auto;
	}
}

caption {
  caption-side: top;
  align: left;
}
// style du titre "MINISTÈRE DE L'ECONOMIE ET DES FINANCES AUTORITE DE REGULATION DES MARCHES PUBLICS"


// Style input file underline
.mat-form-field-underline {
	background-color: transparent!important;
}
// end Style input file underline

// btn cancel confirmation data - table
button#annuler, button#cancel {
	background: #e0e0e0!important;
	color: $color-secondary!important;
	transition: all 0.4s ease-in-out!important;
	margin-top: 20px;
	&:hover{
		background: #00b6d7;
		color: #ffffff;
	}
}

// btn valid confirmation data - table
button#valider, button#confirm {
	background: $color-blue;
	color: #fff!important;
	transition: all 0.4s ease-in-out!important;
	border-radius: 4px;
	margin-top: 20px;
	&:hover {
    color: #F4F7FC;
    transition: 0.4s;
	}
}
// ------------------- style OTP-------------------
.custom-container .mat-dialog-container {
	border-radius: 4px !important;
	background: none!important;
  }

  .custom-container {
  background: none!important;
  }

  .otpblocksucces{
    width: 120px;
    height: 120px;
	margin: 10px auto 0;
	background-image: url('./../images/svg/tick.svg');
  }

  .otpblockfail{
    width: 120px;
    height: 120px;
	margin: 10px auto 0;
	background-image: url('./../images/svg/cancel.svg');
  }
// ------------------- style OTP-------------------
.style-modal-Upload{
	.ngx-file-drop__drop-zone{
		border: none !important;
		border-radius: 5px;
		height: 100px;
		margin: auto;
	}
}

// ------------- Style Checkbox activé ------------
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-background {
	background-color: #00b6d7!important;
}
// ------------- fin Style Checkbox activé ------------


//--------------- Style "event-none" ------------------
.event-none{
	color: #a1aeb7!important;
}
//--------------- fin Style "event-none" ------------------

/* Disabled pointer-events on <a> </a> */
.disabled-link {
	pointer-events: none;
	cursor: default;
}

// changer le background du boutton icone eyeff dans cps annexe

	// .armp-svg{
	// 	font-size: 20px;
	// 	color: #00b6d7;
	// 	vertical-align: middle;
	// }

	.background-icone-eyes{
		border: none;
		background-color: #00b6d7;
    padding: 4px 8px 3px 8px;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    margin: 0px 10px;
		.armp-eyeff{
			color: white;
    	font-size: 13px;
		}
}

.position {
    position: fixed;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
}

.form-label-cust{
	border: 1px solid #d8d8d8;
    background-color: #fff;
    padding: 8px 12px;
    color: #9e9e9e;
}

.btn-danger-armp{
	background-color: #ff4b4b;
    color: #fff;
	&:hover {
		background: #d43c3c;
		color: #F4F7FC;
		transition: 0.4s;
	}
}
/* sometimes, we do'nt need to show material table headers */
.table-no-header thead {
	display: none;
}

/************* Loader Style ****************/
.armp-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.armp-loader:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 15px solid #fff;
  border-color: #00b6d7  transparent #00b6d7 transparent;
  animation: armp-loader 1.2s infinite;
}
@keyframes armp-loader {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
.search-list {
	max-width: 280px;
	border: 1px solid #00b6d7;
	box-sizing: border-box;
}
// style icone impression modifiable
.icone-impression-form{
	color: white;
	font-size: 15px !important;
}

// Debut ACCORDION With texte legend dans e-management style
.the-legend {
	border-style: none;
	border-width: 0;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
	width: auto;
	padding: 0 10px;
}
.the-fieldset {
	border: 1px solid #e0e0e0;
	padding: 10px;
	border-radius: 4px;
}
.background-accordion_{
	background: transparent;
	.btn {
			color:#4f4f4f;
			&:hover{
				text-decoration: none;
				color:#4f4f4f;
			}
			&:focus{
				box-shadow: none;
				text-decoration: none;
			}
	}
}
// Fin ACCORDION With texte legend dans e-management style

.e-management{
	&.statistique{
			display: flex;
			justify-content: space-between;
			.recherche{
					width: 25%;
			}
			.resultat-recherche{
					width: 73%;
			}
	}
}
// Max height du contenue dans l'accordion e-management
// .e-management-Contentaccordion{
// 	max-height: 500px;
// 	overflow: auto;
// }

// Ajout sous menu //

	.dropdown-submenu {
	    position: relative;
	}

	.dropdown-submenu>.dropdown-menu {
	    top: 0;
	    left: 100%;
	    margin-top: -6px;
	    margin-left: -1px;
	    -webkit-border-radius: 0 6px 6px 6px;
	    -moz-border-radius: 0 6px 6px;
	    border-radius: 0 6px 6px 6px;
	}

	.dropdown-submenu:hover>.dropdown-menu {
	    display: block;
	}

	div.dropdown-submenu:after {
	    right: 20px;
		display: block;
		content: " ";
		float: right;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		border-width: 5px 0 5px 5px;
		border-left-color: #cccccc;
		margin-top: 5px;
		margin-right: -10px;
		top: 5px;
		position: absolute;
	}

	.dropdown-item a{
		color: #828282;
	}

	.dropdown-item:hover a,.dropdown-item a:hover{
		color: #ffffff;
		text-decoration: none;
	}


	.dropdown-submenu:hover>a:after {
	    border-left-color: #fff;
	}

	.dropdown-submenu.pull-left {
	    float: none;
	}

	.dropdown-submenu.pull-left>.dropdown-menu {
	    left: -100%;
	    margin-left: 10px;
	    -webkit-border-radius: 6px 0 6px 6px;
	    -moz-border-radius: 6px 0 6px 6px;
	    border-radius: 6px 0 6px 6px;
	}

	.sub-dropdown {
		top: 0 !important;
	}

	// debut style des tables de chaque accordion dans e-management
.table-static-model{
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 0px;
	min-width: 381px;
	thead{
		tr{
			th{
				font-size: 14px;
				text-align: center;
				padding: 10px;
				border: 1px solid #dee2e6;
			}
		}
	}
	tbody{
		tr{
			td{
				padding: 10px;
				font-size: 14px;
				border: 1px solid #dee2e6;
			}
		}
	}
	tfoot{
		tr{
			td{
				padding: 10px;
				font-size: 14px;
				border: 1px solid #dee2e6;
			}
		}
	}
}
.scrollBbar{
	overflow: auto;
}
.border-none{
	border: none !important;
}
// fin style des tables de chaque accordion dans e-management

// style boutton voir plus dans l'accordion e-paiement
.view-more-accordion{
	text-align: right;
	button{
		color: #00b6d7;
	&:hover{
		text-decoration: underline;
		color: #00b6d7;
	}
	&:focus{
		box-shadow: none;
		text-decoration: underline;
	}
	}
}
// style boutton voir plus dans l'accordion e-paiement

.custom-container-modal {
	max-width: 80%;
}

// spinner loading button or input
@keyframes spinner {
    to {transform: rotate(360deg);}
  }

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 24px;
    margin-top: -1px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
	display: none;
  }

  .main-container-spinner {
    position: relative;
}
#iddd{
	overflow: visible !important;
	max-width: 100%;
}

// definier modal card a height 100%
.heightModalCard{
	height: 100%;
}

// z-index toolbar dans TinyMCE e-dossier
.tox-tinymce-aux {
	z-index: 1 !important;
}

// height titre modal
.h-33{
	height: 43px;
}
.bg-table{
	background: #f2f2f2;
}

.rectif-paddingBtnIcone{
	padding: .375rem .75rem !important;
}
.j-content-form{
	justify-content: end;
}

// panier e-paiement (basket)
.formBasket{
	color: #ffffff;
    font-size: 17px;
    cursor: pointer;
	margin: 0 5px;
	text-decoration: none;
	&:hover{
	color: #ffffff;	
	text-decoration: none;
	}
}
// iconify style form
.iconifyStyle{
	font-size: 18px;
	top: 8px;
	.iconify{
		vertical-align: baseline;
	}
}