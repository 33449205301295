@font-face {
	font-family: "icoArmp";
	src: url("./../fonts/iconArmp/icoArmp.eot?adf0hx");
	src: url("./../fonts/iconArmp/icoArmp.eot?adf0hx#iefix")
			format("embedded-opentype"),
		url("./../fonts/iconArmp/icoArmp.ttf?adf0hx") format("truetype"),
		url("./../fonts/iconArmp/icoArmp.woff?adf0hx") format("woff"),
		url("./../fonts/iconArmp/icoArmp.svg?adf0hx#icoArmp") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="armp-"],
[class*=" armp-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icoArmp" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.armp-arrow-back:before {
  content: "\e901";
}
.armp-secteur-public:before {
  content: "\e906";
}
.armp-sectuer-prive:before {
  content: "\e907";
}
.armp-societe-civile-vip:before {
  content: "\e900";
}
.armp-logout:before {
  content: "\e902";
}
.armp-notification:before {
  content: "\e903";
}
.armp-gear:before {
  content: "\e904";
}
.armp-user:before {
  content: "\e905";
}
.armp-icon-plus:before {
  content: "\e908";
}
.armp-ico-delete-table:before {
  content: "\e909";
}
.armp-ico-diffusion-table:before {
  content: "\e90a";
}
.armp-ico-edit-table:before {
  content: "\e90b";
}
.armp-ico-file-table:before {
  content: "\e90c";
}
.armp-ico-info-table:before {
  content: "\e90d";
}
.armp-ico-pdf-table:before {
  content: "\e90e";
}
.armp-ico-refresh-table:before {
  content: "\e90f";
}
.armp-calendar:before {
  content: "\e910";
}
.armp-arrow-down:before {
  content: "\e911";
}
.armp-times:before {
  content: "\e912";
}
.armp-portal:before {
  content: "\e913";
}
.armp-config:before {
  content: "\e914";
}
.armp-finger-point:before {
  content: "\e933";
}
.armp-hand:before {
  content: "\e934";
}
.armp-svg:before {
  content: "\e915";
}
.armp-svgtwo:before {
  content: "\e916";
}
.armp-eyeff:before {
  content: "\e917";
}
.armp-cart-fill:before {
  content: "\e927";
}
.armp-shopping-cart:before {
  content: "\e928";
}
.armp-floppy:before {
  content: "\e922";
}
.armp-clipboard-edit:before {
  content: "\e924";
}
.armp-pen:before {
  content: "\e923";
}
.armp-success-pv:before {
  content: "\e91c";
}
.armp-publish:before {
  content: "\e925";
}
.armp-checkmark:before {
  content: "\e919";
}
.armp-warning:before {
  content: "\e91a";
}
.armp-alert:before {
  content: "\e91a";
}
.armp-warning1:before {
  content: "\ea07";
}
.armp-check-alt:before {
  content: "\e918";
}
.armp-cross:before {
  content: "\e91b";
}
.armp-cross-circle:before {
  content: "\e91d";
}
.armp-download:before {
  content: "\e9c7";
}
.armp-newspaper:before {
  content: "\e91e";
}
.armp-book:before {
  content: "\e91f";
}
.armp-pencil:before {
  content: "\e920";
}
.armp-eye:before {
  content: "\e9ce";
}
.armp-evaluate-design:before {
  content: "\e9d2";
}
.armp-mail-reply-all:before {
  content: "\e926";
}
.armp-clipboard:before {
  content: "\e921";
}
